import { usePermissions } from '@/Hooks/Company/usePermissions'
import { useAuth } from '@/Hooks/Company/useAuth'
import { useFeatureFlags } from '@/Hooks/Company/useFeatureFlags'

export default function useAppStoreNavigation() {
  const { authCompanyUser, authCompany, authOrganization } = useAuth()
  const { hasPermission } = usePermissions()
  const { hasFeatureFlag } = useFeatureFlags()

  return {
    overviewTitle: 'App Store',
    overviewRouteName: 'web.company.app-store.show',
    sections: [
      {
        show: hasPermission('app_store_view_any') && authOrganization.value.is_pro_account,
        name: "Aryeo's Services",
        description: 'Aryeo features you can enable for your business.',
        children: [
          {
            show: hasPermission('product_view_any'),
            name: 'Contractor Payroll',
            description: 'Manage payroll for your team members with Aryeo.',
            routeIsInertia: true,
            targetRouteName: 'web.company.features.payroll.show',
            image:
              'https://aryeo.sfo2.cdn.digitaloceanspaces.com/aryeo_media/app_store_tiles/aryeo-payroll-banner.png',
            routeNamesToCheckIfCurrent: ['features.payroll.*'],
            aryeoService: 'PAYROLL',
          },
          {
            show: true,
            name: 'Tipping',
            description: 'Allow customers to add tips to payments.',
            routeIsInertia: true,
            targetRouteName: 'web.company.features.tipping.show',
            image:
              'https://aryeo.sfo2.cdn.digitaloceanspaces.com/aryeo_media/app_store_tiles/aryeo-tipping-banner.png',
            routeNamesToCheckIfCurrent: ['web.company.features.tipping.*'],
            aryeoService: 'TIPPING',
          },
          {
            show: true, // hasPlanPermission('can_create_listing_embeds')
            name: 'Aryeo Listing Embeds',
            description: 'Showcase your Aryeo property listings, directly on your website.',
            routeIsInertia: true,
            targetRouteName: 'web.company.embeds.index',
            image:
              'https://aryeo.sfo2.cdn.digitaloceanspaces.com/aryeo_media/files/Aryeo%20Blog-%20App%20Store%20-%20Quickbooks%20%282%29.png',
            routeNamesToCheckIfCurrent: ['web.company.embeds.*'],
            aryeoService: 'LISTING_EMBED',
          },
          {
            show: true,
            name: 'Mileage Reports',
            description:
              'Enable access to Mileage Reports for calculating mileage-based payouts for your team members.',
            routeIsInertia: true,
            targetRouteName: 'web.company.features.mileage-reports.show',
            image:
              'https://aryeo.sfo2.cdn.digitaloceanspaces.com/aryeo_media/app_store_tiles/aryeo-mileage-reports.png',
            routeNamesToCheckIfCurrent: ['web.company.features.mileage-reports.*'],
            aryeoService: 'MILEAGE_REPORTS',
          },
        ],
      },
      {
        show: hasPermission('app_store_view_any') && authOrganization.value.is_pro_account,
        name: 'Zillow Partnerships',
        description: 'Opt-in to Zillow partnerships exclusive to Aryeo customers.',
        children: [
          {
            show: hasFeatureFlag('showcase_app'),
            name: 'Showcase',
            description:
              'Get more business by signing up to capture Showcase media and more for agents who use the ShowingTime+ platform.',
            routeIsInertia: true,
            targetRouteName: 'web.company.integrations.showcase.show',
            image:
              'https://aryeo.sfo2.cdn.digitaloceanspaces.com/aryeo_media/app_store_tiles/aryeo-listing-showcase-by-zillow-logo.png',
            routeNamesToCheckIfCurrent: ['web.company.integrations.showcase.show.*'],
            integrationProvider: 'SHOWCASE', // TODO: Make an integration for this
          },
          {
            show: hasFeatureFlag('zillow_rentals'),
            name: 'Zillow Rentals',
            description:
              'Sign up to join our exclusive media provider list for Zillow Rentals Partners',
            routeIsInertia: true,
            targetRouteName: 'web.company.integrations.zillow-rentals.show',
            image:
              'https://aryeo.sfo2.cdn.digitaloceanspaces.com/aryeo_media/app_store_tiles/zillow-rentals-logo.jpg',
            imageObjectFit: 'contain',
            routeNamesToCheckIfCurrent: ['web.company.integrations.zillow-rentals.show.*'],
            integrationProvider: 'ZILLOW_RENTALS',
          },
        ],
      },
      {
        show: hasPermission('app_store_view_any') && authOrganization.value.is_pro_account,
        name: 'Payments',
        description: 'Enable accepting payment for invoices.',
        children: [
          {
            show: true,
            name: 'Stripe',
            description: 'Set up Stripe as an available payment processor for your business.',
            routeIsInertia: true,
            targetRouteName: 'web.company.integrations.stripe-connect.show',
            image: 'https://stripe.com/img/v3/home/social.png',
            routeNamesToCheckIfCurrent: ['web.company.integrations.stripe-connect.*'],
            integrationProvider: 'STRIPE_CONNECT',
          },
          {
            show: true,
            name: 'Square',
            description: 'Set up Square as an available payment processor for your business.',
            routeIsInertia: true,
            targetRouteName: 'web.company.integrations.square-merchant.show',
            image: 'https://findvectorlogo.com/wp-content/uploads/2018/12/square-vector-logo.png',
            routeNamesToCheckIfCurrent: ['web.company.integrations.square-merchant.*'],
            integrationProvider: 'SQUARE_MERCHANT',
          },
        ],
      },
      {
        show: hasFeatureFlag('avalara_taxes') && authOrganization.value.is_pro_account,
        name: 'Taxes',
        description: 'Calculate taxes for your orders.',
        children: [
          {
            show: true,
            name: 'Avalara',
            description: 'Use Avalara to calculate taxes for your orders.',
            routeIsInertia: true,
            targetRouteName: 'web.company.integrations.avalara.show',
            image:
              'https://aryeo.sfo2.cdn.digitaloceanspaces.com/aryeo_media/app_store_tiles/avalara-app-store-tile.jpg',
            imageObjectFit: 'cover',
            routeNamesToCheckIfCurrent: ['web.company.integrations.avalara.*'],
            integrationProvider: 'AVALARA',
          },
        ],
      },
      {
        show: authOrganization.value.is_pro_account,
        name: 'Calendar',
        description: 'Sync appointments in Aryeo and account for real time availability.',
        children: [
          {
            show: true,
            name: 'Google Calendar',
            description:
              'Two-way sync between Aryeo and your Google calendar for appointments and calendar blocks.',
            routeIsInertia: true,
            targetRouteName: 'web.company.integrations.google.show',
            image:
              'https://aryeo.sfo2.cdn.digitaloceanspaces.com/aryeo_media/files/google-calendar.jpeg',
            routeNamesToCheckIfCurrent: ['web.company.integrations.google.*'],
            integrationProvider: 'GOOGLE',
          },
          {
            show: true,
            name: 'Outlook Calendar',
            description:
              'Two-way sync between Aryeo and your Outlook calendar for appointments and calendar blocks.',
            routeIsInertia: true,
            targetRouteName: 'web.company.integrations.azure.show',
            image:
              'https://aryeo.sfo2.cdn.digitaloceanspaces.com/aryeo_media/files/outlook-calendar.jpg',
            routeNamesToCheckIfCurrent: ['web.company.integrations.azure.*'],
            integrationProvider: 'AZURE',
          },
          {
            show: true,
            name: 'Other Calendars',
            description:
              'This calendar integration is our legacy connection and should only be used if you are not using Google, Outlook or Microsoft 365 calendars.',
            routeIsInertia: true,
            targetRouteName: 'web.company.integrations.nylas.show',
            image:
              'https://aryeo.sfo2.cdn.digitaloceanspaces.com/aryeo_media/app_store_tiles/aryeo-other-calendars.png',
            routeNamesToCheckIfCurrent: ['web.company.integrations.nylas.*'],
            integrationProvider: 'NYLAS',
            legacy: true,
          },
        ],
      },
      {
        show: hasPermission('app_store_view_any'),
        name: 'Media Generation',
        description: 'Tools for generating media like floor plans and 3D tours.',
        children: [
          {
            show: authOrganization.value.is_pro_account,
            name: 'CubiCasa',
            description: 'An industry leading floor plan capture solution.',
            routeIsInertia: true,
            targetRouteName: 'web.company.integrations.cubicasa.show',
            image:
              'https://cubicasa-wordpress-uploads.s3.amazonaws.com/uploads/cubicasa-logo/logo+dark+with+background.png',
            imageObjectFit: 'contain',
            routeNamesToCheckIfCurrent: ['web.company.integrations.cubicasa.*'],
            integrationProvider: 'CUBICASA',
          },
          {
            show: authOrganization.value.is_enterprise_customer,
            name: 'Esoft',
            description: 'Create orders and receive media edits directly to your Aryeo listings.',
            routeIsInertia: true,
            targetRouteName: 'web.company.integrations.esoft.show',
            image:
              'https://aryeo.sfo2.cdn.digitaloceanspaces.com/aryeo_media/app_store_tiles/aryeo-esoft-logo.png',
            routeNamesToCheckIfCurrent: ['web.company.integrations.esoft.*'],
            integrationProvider: 'ESOFT',
          },
          {
            show: hasFeatureFlag('showingtime'),
            name: 'Zillow 3D Home',
            description: 'Make your listing pop with Zillow 3D Home® tours.',
            routeIsInertia: true,
            targetRouteName: 'web.company.integrations.zillow-3d-home.show',
            image: 'https://miro.medium.com/v2/resize:fit:700/0*t7CCAHnIhjfEpyU-.png',
            routeNamesToCheckIfCurrent: ['web.company.integrations.zillow-3d-home.*'],
            integrationProvider: 'ZILLOW_3D_HOME',
          },
          {
            show: true,
            name: 'Vimeo',
            description:
              'Seamlessly embed videos from the Vimeo platform. This is enabled by default.',
            routeIsInertia: true,
            targetRouteName: null,
            image:
              'https://aem.dropbox.com/cms/content/dam/dropbox/www/en-us/business/app-integrations/vimeo/vimeo-logo.png',
            imageObjectFit: 'contain',
            routeNamesToCheckIfCurrent: [],
            integrationProvider: 'VIMEO',
          },
          {
            show: true,
            name: 'Youtube',
            description:
              'Host and stream videos from the YouTube platform. This is enabled by default.',
            routeIsInertia: true,
            targetRouteName: null,
            image: 'https://www.youtube.com/img/desktop/yt_1200.png',
            routeNamesToCheckIfCurrent: [],
            integrationProvider: 'YOUTUBE',
          },
          {
            show: true,
            name: 'iGuide',
            description: 'Emded 3D tours from the iGuide platform. This is enabled by default.',
            routeIsInertia: true,
            targetRouteName: null,
            image: 'https://goiguide.com/wp-content/uploads/2019/09/iguideR_50.png',
            imageObjectFit: 'contain',
            routeNamesToCheckIfCurrent: [],
            integrationProvider: 'IGUIDE',
          },
        ],
      },
      {
        show: hasPermission('app_store_view_any') && authOrganization.value.is_pro_account,
        name: 'Accounting',
        description: "Accounting tools integrated with Aryeo's payment processing",
        children: [
          {
            show:
              hasFeatureFlag('quickbooks_app') ||
              authCompanyUser.value.is_super ||
              new Date(authCompany.created_at) < new Date('2021-08-01'),
            name: 'Quickbooks',
            description: 'Accounting software for small businesses.',
            routeIsInertia: true,
            targetRouteName: 'web.company.integrations.quickbooks.show',
            image: 'https://upload.wikimedia.org/wikipedia/commons/c/ce/Intuit_QuickBooks_logo.png',
            imageObjectFit: 'contain',
            routeNamesToCheckIfCurrent: ['web.company.integrations.quickbooks.*'],
            integrationProvider: 'QUICKBOOKS',
            isRestricted: true,
          },
        ],
      },
      {
        show: hasPermission('app_store_view_any') && authOrganization.value.is_pro_account,
        name: 'Automation',
        description:
          'Leverage Aryeo for your external workflows to elevate your business operations.',
        children: [
          {
            show: true,
            name: 'Zapier',
            description: 'Easy automation for busy people.',
            routeIsInertia: true,
            targetRouteName: 'web.company.integrations.zapier.show',
            image:
              'https://247263.apps.zdusercontent.com/247263/assets/1586197412-ada46c151fe02221e43cbcb9120c8fcf/logo.png',
            routeNamesToCheckIfCurrent: ['web.company.integrations.zapier.*'],
            integrationProvider: 'ZAPIER',
          },
        ],
      },
      {
        show: hasPermission('app_store_view_any') && authOrganization.value.is_pro_account,
        name: 'Chat',
        description: 'Communicate with customers within your customer portal with ease.',
        children: [
          {
            show: true,
            name: 'Smallchat',
            description: 'Talk to your visitors without leaving Slack.',
            routeIsInertia: true,
            targetRouteName: 'web.company.chat.integrations.smallchat.edit',
            image: 'https://nocode.b-cdn.net/nocode/tools/Smallchat-logo.jpeg',
            routeNamesToCheckIfCurrent: ['web.company.chat.integrations.smallchat.*'],
            integrationProvider: 'SMALLCHAT',
          },
          {
            show: true,
            name: 'Intercom',
            description: 'Robust customer support, sales, and marketing.',
            routeIsInertia: true,
            targetRouteName: 'web.company.chat.integrations.intercom.edit',
            image: 'https://www.thesoftwarereport.com/wp-content/uploads/2020/08/Intercom.png',
            routeNamesToCheckIfCurrent: ['web.company.chat.integrations.intercom.*'],
            integrationProvider: 'INTERCOM',
          },
          {
            show: true,
            name: 'Zendesk',
            description: 'Customer services software & CRM.',
            routeIsInertia: true,
            targetRouteName: 'web.company.chat.integrations.zendesk.edit',
            image: 'https://d1eipm3vz40hy0.cloudfront.net/images/part-header/zendesk-logo-amp.png',
            imageObjectFit: 'contain',
            routeNamesToCheckIfCurrent: ['web.company.chat.integrations.zendesk.*'],
            integrationProvider: 'ZENDESK',
          },
          {
            show: true,
            name: 'Freshdesk',
            description: 'Multi-channel customer support platform.',
            routeIsInertia: true,
            targetRouteName: 'web.company.chat.integrations.freshdesk.edit',
            image:
              'https://i.pcmag.com/imagery/reviews/05GWTydEGZMXuYjL2Oo0mRa-9.fit_scale.size_760x427.v1569475483.jpg',
            routeNamesToCheckIfCurrent: ['web.company.chat.integrations.freshdesk.*'],
            integrationProvider: 'FRESHDESK',
          },
        ],
      },
      {
        show: hasPermission('app_store_view_any') && authOrganization.value.is_pro_account,
        name: 'Analytics',
        description: 'Tools for adding analytics to your customer portal and order forms pages.',
        children: [
          {
            show: hasPermission('app_store_view_any'),
            name: 'Google Tag Manager',
            description:
              'Add your Google Tag Manager ID to have it included on your customer portal pages and order forms.',
            routeIsInertia: true,
            targetRouteName: 'web.company.integrations.google-tag-manager.edit',
            image:
              'https://aryeo.sfo2.cdn.digitaloceanspaces.com/aryeo_media/app_store_tiles/google-tag-manager-logo.jpg',
            imageObjectFit: 'contain',
            routeNamesToCheckIfCurrent: ['web.company.integrations.google-tag-manager.edit.*'],
            aryeoService: 'GOOGLE_TAG_MANAGER',
          },
        ],
      },
    ],
  }
}
